.card-clicable > * {
    cursor: pointer;
}

.card-hoverable:hover {
    background-color: #00000010;
}

.toolbar-div > .col > * {
    margin-right: 10px !important;
}

.search-box-div input.form-control {
    height: 100%;
    padding-left: 35px;
}