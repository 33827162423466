.tag-item {
    color: white;
    vertical-align: middle;
    font-size: 12px;
    margin-bottom: 5px;
    margin-right: 5px;

    span {
        padding: 5px;
        display: inline-block;
    }

    .feather:hover {
        color: #ccc;
        cursor: pointer;
    }
}