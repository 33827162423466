.table-card-block {
    table {
        margin-bottom: 0;

        &.clicable > * {
            cursor: pointer;
        }

        tbody {
            tr:nth-child(even) {
                background-color: #f2f2f2;
            }
            tr:nth-child(odd) {
                background-color: #f9f9f9;
            }
            tr:hover {
                background-color: rgba(4, 169, 245, 0.05);
            }
        }
    }
}

.DraftEditor-editorContainer {
    background: #f4f7fa !important;
    padding: 10px 20px;
    font-size: 14px;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem;
}

.userProfileBox {
    .user-avatar {
        margin: 0 auto;
        max-width: 250px;

        img {
            border-radius: 50%;
            width: 100%;
        }
    }
}