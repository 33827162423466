.chat-user-list > .chat-user-list-item {

    padding: 10px;
    border-bottom: 1px solid #dfdfdf;
    cursor: pointer;
    flex-direction: row;
    display: flex;

    &:hover { 
        background: #0000000d;
    }

}

.user-chat-header {
    display: flex;
    flex-direction: row;

    .user-avatar {
        margin-right: 15px;
    }

    .user-info {
        .user-name {
            font-size: 18px;
        }
    }
}

.message-box-body {
    background-color: #e9e9e9;
    max-height: calc(100vh - 303px);
    overflow-y: scroll;

    .rce-mbox {
        max-width: 400px;
        .rce-mbox-right {
            
        }
    }
}
